import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";

import SEO from "@components/shared/seo";
import {
  isSanityBackgroundImageWithText,
  isSanityColoredTextColumns,
  isSanityColumn,
  isSanityImageBlock,
  isSanityImageColumns,
  isSanityRelatedWork,
  isSanityScrollContainer,
  isSanityTextCta,
  isSanityVideoBlock,
} from "@util/types";
import WorkInnerHero from "@components/workInner/workInnerHero";
import {
  BlocksContent,
  ColoredTextColumns,
  Image,
  TextCta,
  Video,
} from "@components/shared/sub";
import { Container } from "@util/standard";
import { FluidObject } from "gatsby-image";
import ImageColumns from "@components/workInner/imageColumns";
import BackgroundImageWithText from "@components/workInner/backgroundImageWithText";
import RelatedWork from "@components/whatWeDoInner/relatedWork";
import ScrollContainer from "@components/workInner/scrollContainer";
import { VideoWrapper } from "./template.styles";

interface Props extends PageProps {
  data: Query;
  pageContext: any;
}

export default function OfferingTemplate({ data, pageContext }: Props) {
  const offering = data.allSanityOfferingsInner.nodes[0];

  if (offering == null) {
    return null;
  }

  const { seo, hero, pageContent } = offering;

  return (
    <div>
      <SEO seoData={seo} slug={pageContext?.slug} />
      {hero && <WorkInnerHero data={hero} />}
      <Container
        flexDirection="column"
        width="100%"
        margin="150px auto 0 auto"
        tabletMargin="25px auto 0 auto"
      >
        {pageContent &&
          pageContent.map((data, index) => {
            if (data == null) return null;

            const isLast = index === pageContent.length - 1;

            if (isSanityImageBlock(data)) {
              if (data == null || data.image == null) return null;
              return (
                <Container
                  width={`${data.width ?? 100}%`}
                  height={`${data.height ?? 100}%`}
                  tabletWidth="100%"
                  margin={isLast ? "0 auto 60px auto" : "0 auto 120px auto"}
                  tabletMargin={isLast ? "0 auto " : "0 auto 25px auto"}
                >
                  <Image fluid={data.image.asset?.fluid as FluidObject} />
                </Container>
              );
            }

            if (isSanityImageColumns(data)) {
              return <ImageColumns data={data} />;
            }

            if (isSanityBackgroundImageWithText(data)) {
              return <BackgroundImageWithText data={data} />;
            }

            if (isSanityColoredTextColumns(data)) {
              return <ColoredTextColumns data={data} />;
            }

            if (isSanityTextCta(data)) {
              return <TextCta data={data} />;
            }

            if (isSanityColumn(data)) {
              return (
                <Container
                  width="68%"
                  margin={isLast ? "0 auto 60px auto" : "0 auto 120px auto"}
                  tabletMargin={isLast ? "0 auto " : "0 auto 25px auto"}
                >
                  <BlocksContent blocks={data._rawColumnContent} />
                </Container>
              );
            }

            if (isSanityRelatedWork(data)) {
              return <RelatedWork content={data} />;
            }

            if (isSanityScrollContainer(data)) {
              return <ScrollContainer data={data} />;
            }
            if (isSanityVideoBlock(data)) {
              return (
                <VideoWrapper
                  key={data._key}
                  tabletMarginOverride="0 auto 25px auto"
                >
                  <Video
                    background
                    url={data.vimeoURL as string}
                    volume={false}
                    foregroundOpen
                  />
                </VideoWrapper>
              );
            }

            return null;
          })}
      </Container>
    </div>
  );
}

export const query = graphql`
  query offeringQuery($slug: String) {
    allSanityOfferingsInner(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        seo {
          pageDescription
          pageKeyWords
          pageTitle
          slug {
            current
          }
          ogImage {
            asset {
              url
            }
          }
        }
        _id
        name
        slug {
          current
        }
        hero {
          backgroundImage {
            asset {
              fluid(maxWidth: 2000) {
                src
              }
            }
          }
          logo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          headerText {
            _rawColumnContent
          }
        }
        pageContent {
          ... on SanityImageBlock {
            ...sanityImageBlock
          }
          ... on SanityImageTextColumn {
            _key
            _type
          }
          ... on SanityVideoBlock {
            ...sanityVideoBlock
          }
          ... on SanityScrollContainer {
            ...sanityScrollContainer
          }
          ... on SanityImageColumns {
            _key
            _type
            columnOne {
              asset {
                fluid {
                  src
                  ...GatsbySanityImageFluid
                }
              }
            }
            columnTwo {
              asset {
                fluid {
                  src
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityColoredTextColumn {
            _key
            _type
            columnTwoBackground
            columnOneBackground
            title
            columnTwoText {
              _rawColumnContent
            }
            columnOneText {
              _rawColumnContent
            }
          }
          ... on SanityColumn {
            _key
            _type
            _rawColumnContent
          }
          ... on SanityTextCta {
            _key
            _type
            title
            textContent {
              _rawColumnContent
            }
            cta {
              ...sanityLink
            }
          }
          ... on SanityRelatedWork {
            _key
            _type
            relatedWorkList {
              images {
                asset {
                  fluid {
                    srcWebp
                    srcSetWebp
                    ...GatsbySanityImageFluid
                  }
                }
              }
              title
              summary
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`;
